<template>
  <div class="start-landing">
    <b-row no-gutters>
      <b-col>
        <h1 class="text-uppercase text-center">
          Experience The Future Of SEO Now
        </h1>

        <h2 class="text-center">
          Leverage Custom AI for Content That Ranks, Captures and Converts
        </h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12">
        <StepsPromo @click="$emit('next')" />

        <div class="d-flex align-items-center justify-content-center flex-wrap mt-4">
          <div class="d-flex mr-3 mb-3">
            <img src="@/assets/images/forbes-thailand.png" width="100" class="mr-3 mb-3" />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="h2 font-handwriting text-center text-capitalize">
          {{ localize("Here's What Your New Best Friend Will Do For You") }}
        </h2>
        <ul class="features text-left mt-4 helvetica p-0">
          <li v-for="value in pros" :key="String(value)" class="d-flex mb-2">
            <img src="@/assets/icons/check.svg" class="icon m-0" />
            <span class="ml-3" v-html="localize(value)"></span>
          </li>
        </ul>
      </b-col>
    </b-row>

    <!--  <b-row>
      <b-col>
        <h2 class="h2 font-handwriting text-center">
          {{ localize("Thousands Of Authors Are Now Using QuietQuill To Write Better And Faster. Now It's Your Turn.") }}
        </h2>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col cols="12" md="6" class="pr-0 pr-md-2">
        <img class="d-block mt-3" src="/img/reviews/1.png" />
        <img class="d-block mt-3" src="/img/reviews/2.png" />
        <img class="d-block mt-3" src="/img/reviews/3.png" />
      </b-col>
      <b-col cols="12" md="6" class="pl-0 pl-md-2">
        <img class="d-block mt-3" src="/img/reviews/4.png" />
        <img class="d-block mt-3" src="/img/reviews/5.png" />
        <img class="d-block mt-3" src="/img/reviews/6.png" />
      </b-col>
    </b-row> -->

    <b-row class="mt-5 pt-0 pt-md-5">
      <b-col cols="12" md="6" class="d-none d-md-block">
        <div>
          <img
            class="d-block w-100"
            src="@/assets/images/lp-banner.png"
            alt="Claim your Draft book now"
            style="filter: sepia(100%) grayscale(60%);"
          />
        </div>

        <div class="d-flex align-items-center justify-content-center flex-wrap mt-4">
          <div class="d-flex mr-3 mb-3">
            <img src="@/assets/images/forbes-thailand.png" width="100" class="mr-3 mb-3" />
          </div>
        </div>
      </b-col>

      <b-col cols="12" md="6">
        <StepsPromo @click="$emit('next')" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue";

import { toSlug } from "@/helpers/utils";

import StepsPromo from "@/components/Data/StepsPromo.vue";

export default {
  name: "Start",
  components: { BCol, BRow, StepsPromo },
  computed: {
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data() {
    return {
      pros: [
        `<b>Automated Keyword Research:<b> Dive into your topic with keywords generated by advanced AI, tailored to boost your content's visibility.`,
        `<b>Dynamic Heading Structure:</b> Effortlessly organize your articles with AI-crafted headings that capture attention and enhance readability.`,
        `<b>Instant Meta Magic:</b> Generate compelling meta descriptions and titles that elevate your content's click-through rate.`,
        //`<b>Optimized Link Integration:</b> Improve your site's authority with smartly placed, optimized links within your article.`,
        `<b>Seamless Text Formatting:</b> Achieve the perfect article layout with AI-guided text formatting, making your content more engaging.`,
        // `<b>Content Strategy Optimization:</b> Receive personalized recommendations to align your articles with the latest SEO trends and best practices.`,
        `<b>Time-Saving Article Copy Creation:</b> Quickly produce long-form SEO blog posts that are primed for top search engine rankings.`,
        `<b>Personalized AI Writing Style:</b> Train MeetLouis.ai with your unique writing samples, enabling it to craft articles that mirror your personal tone and style, ensuring your content remains authentic and true to your voice.`,
      ],
    };
  },
  methods: {
    toSlug,
    localize(value) {
      return value;
    },
  },
};
</script>
